import React from 'react';
import cn from 'classnames';
import { Modal } from 'antd';
import { WarningOutlineIcon } from '../../../../../icons';

import styles from './CloseModal.module.scss';

export const CloseWeekModal = (props) => {
  const {
    visible,
    hiddeModal,
    closeWeek,
    contentText,
    cancelText,
    closeWeekText,
    contentsubText
  } = props;

  return (
    <Modal
      wrapClassName={`activity-modification-style ${styles['facelifted-modal']} ${styles['work-hours-modal']}`}
      visible={visible}
      onCancel={hiddeModal}
      footer={null}
      width={380}>
      <div className={styles.content}>
        <WarningOutlineIcon color="#7DFF8A" className={styles.content__icon} />
        <div className={styles.content__text}>
          <p>{contentText}</p>
          <p>{contentsubText}</p>
        </div>
        <div>
          <button
            onClick={hiddeModal}
            className={cn(styles.btn, styles.cancel)}>
            {cancelText}
          </button>
          <button onClick={closeWeek} className={cn(styles.btn, styles.close)}>
            {closeWeekText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
