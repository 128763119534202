import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';

const renderRow = (templateData, data) => {
  function Row({ index, style }) {
    const currentStyle = {
      ...style,
      gridTemplateColumns:
        templateData.gridTemplateColumns ||
        `repeat(${templateData.columns?.length}, auto)`
    };

    return (
      <div style={currentStyle} className="list__content">
        {templateData.columns?.map((col, key) => (
          <div
            className="content__row"
            key={`${index}-${key}`}
            style={{
              justifyContent: col.justifyContentColumn || 'flex-start'
            }}>
            {col.renderColumn(data[index], index)}
          </div>
        ))}
      </div>
    );
  }

  Row.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired
  };

  return Row;
};

const VirtualDynamicTable = ({
  templateData,
  data,
  itemCount,
  itemSize,
  heightRow
}) => {
  const columnsToShow = templateData.columns.filter(
    (column) => column.shouldRender
  );
  const gridTemplateColumns = columnsToShow
    .map((column) => column.gridFraction)
    .join(' ');
  return (
    <div className="virtual-dynamic-table">
      <div
        className="virtual-dynamic-table__header"
        style={{
          height: templateData.headerHeight || 24,
          gridTemplateColumns:
            gridTemplateColumns || `repeat(${columnsToShow.length}, auto)`
        }}>
        {templateData.columns?.map(
          (column) =>
            column.shouldRender && (
              <div
                key={column.key}
                className="header__content"
                style={{
                  justifyContent: column.justifyContentHeader || 'flex-start'
                }}>
                <span
                  onClick={() =>
                    column.onClickHeader ? column.onClickHeader() : null
                  }
                  style={{
                    cursor: column.onClickHeader ? 'pointer' : 'inherit'
                  }}>
                  {column.renderHeader ? column.renderHeader() : column.label}
                </span>
              </div>
            )
        )}
      </div>
      <div className="virtual-dynamic-table__body">
        <List
          width="100%"
          height={heightRow}
          itemCount={itemCount}
          itemSize={itemSize}
          className="body__list">
          {renderRow(
            { ...templateData, gridTemplateColumns, columns: columnsToShow },
            data
          )}
        </List>
      </div>
    </div>
  );
};

VirtualDynamicTable.propTypes = {
  templateData: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        gridFraction: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        justifyContentHeader: PropTypes.string.isRequired,
        renderHeader: PropTypes.func,
        onClickHeader: PropTypes.func,
        shouldRender: PropTypes.bool.isRequired,
        justifyContentColumn: PropTypes.string.isRequired,
        renderColumn: PropTypes.func.isRequired
      })
    ).isRequired,
    gridTemplateColumns: PropTypes.string,
    headerHeight: PropTypes.number
  }).isRequired,
  data: PropTypes.array.isRequired,
  itemCount: PropTypes.number.isRequired,
  itemSize: PropTypes.number.isRequired,
  heightRow: PropTypes.number.isRequired
};

export default VirtualDynamicTable;
