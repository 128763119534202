import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import i18n from '../../../../../i18n';
import styles from './Timeline.module.css';
import { ganttAPI } from '../../../../../utils/customGanttPlugin';
import moment from 'moment';
import { formatMoney } from '../../../../../utils/lookahead-common';
import { getCompanyTextColor } from '../../../../../utils';

const EMPTY_PLAN_ENDOWMENT = 0;
const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];

const Timeline = (props) => {
  const { tasks } = props;

  return (
    <>
      {tasks.map((task) => (
        <>
          <RecursiveTask task={task} key={task.id} {...props} />
          {task.children.length > 0 &&
            task.children.map((taskChild) => (
              <>
                <RecursiveTask {...props} task={taskChild} key={taskChild.id} />
                {taskChild.children.length > 0 &&
                  taskChild.children.map((taskChild1) => (
                    <>
                      <RecursiveTask
                        {...props}
                        task={taskChild1}
                        key={taskChild1.id}
                      />
                      {taskChild1.children.length > 0 &&
                        taskChild1.children.map((taskChild2) => (
                          <>
                            <RecursiveTask
                              {...props}
                              task={taskChild2}
                              key={taskChild2.id}
                            />
                            {taskChild2.children.length > 0 &&
                              taskChild2.children.map((taskChild3) => (
                                <>
                                  <RecursiveTask
                                    {...props}
                                    task={taskChild3}
                                    key={taskChild3.id}
                                  />
                                  {taskChild3.children.length > 0 &&
                                    taskChild3.children.map((taskChild4) => (
                                      <>
                                        <RecursiveTask
                                          {...props}
                                          task={taskChild4}
                                          key={taskChild4.id}
                                        />
                                        {taskChild4.children.length > 0 &&
                                          taskChild4.children.map(
                                            (taskChild5) => (
                                              <RecursiveTask
                                                {...props}
                                                task={taskChild5}
                                                key={taskChild5.id}
                                              />
                                            )
                                          )}
                                      </>
                                    ))}
                                </>
                              ))}
                          </>
                        ))}
                    </>
                  ))}
              </>
            ))}
        </>
      ))}
    </>
  );
};

const RecursiveTask = (props) => {
  const { task } = props;

  const year = props.year;
  const numberWeek = props.week;
  const planificationDay = props.planificationDay;
  const calendars = props.calendars;
  const activity = props.activity;
  const [isloadgrid, setIsloadgrid] = useState(false);
  const loadCalendars = (calendars) => {
    ganttAPI.loadCalendars(calendars);
    setIsloadgrid(true);
  };

  useEffect(() => {
    calendars.length && loadCalendars(calendars);
  }, [calendars]);

  function validateDay(task, activity, day) {
    const calendar = ganttAPI.getTaskCalendar(activity.calendarId);
    let start_date;
    let end_date;
    if (task.taskcommitments.length > 0) {
      start_date = task.taskcommitments[0].start_date
        ? task.taskcommitments[0].start_date
        : task.start_date;
      end_date = task.taskcommitments[0].end_date
        ? task.taskcommitments[0].end_date
        : task.end_date;
    } else {
      start_date = task.start_date;
      end_date = task.end_date;
    }

    if (!calendar) return false;

    const validate_date = moment()
      .lang('es')
      .year(year)
      .week(numberWeek)
      .startOf('isoweek')
      .add('days', planificationDay)
      .set('hour', '9');
    const copyOfDate = new Date(
      validate_date.add('days', day).format('YYYY/MM/DD H:mm')
    );
    const validate_day = validate_date.isBetween(
      moment(start_date).subtract(1, 'd'),
      moment(end_date).add(1, 'd'),
      'days',
      false
    );

    if (!validate_day) {
      return false;
    }

    // Funcion mal utilizada deberia ser {date, unit: 'day'}
    return calendar.isWorkTime({ date: copyOfDate, unit: 'day' });
  }

  if (task.taskcommitments.length <= 0) return null;

  const DaySpan = ({ task, activity, day }) => {
    const isValid = validateDay(task, activity, day);
    const backgroundColor = task.subcontract?.color ?? '#F59D04';
    const textColor = getCompanyTextColor(task.subcontract?.color);

    return (
      <span
        className={cn({
          [styles['border-subcontract']]: isValid
        })}
        style={
          isValid
            ? {
                background: backgroundColor,
                color: textColor
              }
            : null
        }>
        {isValid
          ? task.plan_endowment > EMPTY_PLAN_ENDOWMENT && task.plan_endowment
          : ''}
      </span>
    );
  };

  if (!isloadgrid) return null;
  return (
    <div
      className={cn(styles.row, {
        [styles['parent-task-color']]: task.children.length
      })}
      key={task.id}>
      {daysOfWeek.map((day) => (
        <DaySpan key={day} task={task} activity={activity} day={day} />
      ))}
    </div>
  );
};

export default Timeline;
