import * as icons from '../../../../../icons';

import { WeeklyPlanCalendar } from './Components/Calendar/Calendar';

export const TOOLBAR_MIDDLE_GROUP_ITEMS = [
  [
    {
      icon: icons.ChevronLeftIcon,
      command: 'CHANGE_PREV_WEEK'
    },
    {
      props: {},
      comp: WeeklyPlanCalendar
    },
    {
      icon: icons.ChevronRightIcon,
      command: 'CHANGE_NEXT_WEEK'
    }
  ]
];

export const TOOLBAR_RIGTH_GROUP_ITEMS = [
  {
    id: 'view-deleted-task',
    icon: icons.DeletedTaskIcon,
    direction: 'RIGHT',
    tooltipI18nKey: 'Deleted Tasks',
    command: 'SHOW_MODAL_DELETED_TASK'
  },
  {
    id: 'download-options',
    icon: icons.BlankDocumentIcon,
    direction: 'RIGHT',
    tooltipI18nKey: 'filters_label.download_label',
    subitems: [
      {
        icon: icons.PdfIcon,
        // iconColor: colors.brandBlue40,
        tooltipI18nKey: 'download_exportable_general',
        i18nKey: 'week_commitment_pdf',
        command: 'DOWNLOAD_SCHEDULE'
      },
      {
        props: {},
        // Comp: LookAheadPlanExport,
        command: 'DOWNLOAD_SCHEDULE_EXCEL',
        icon: icons.ExcelIcon,
        i18nKey: 'week_commitment_excel'
        // iconColor: colors.brandBlue40
      }
    ]
  },
  {
    switchable: [
      {
        icon: icons.ProductivityWeeklyIcon,
        command: 'PROGRESS_COLS',
        iconColor: '#121212',
        active: true
      },
      {
        icon: icons.ProgressWeeklyIcon,
        command: 'PRODUCTIVITY_COLS',
        iconColor: '#121212'
      }
    ]
  },

  [
    {
      i18nKey: 'weekcommitment.close_week',
      icon: icons.CalendarSolidIcon,
      invertTextColor: true,
      command: 'GO_TO_LOOKAHED'
    }
  ]
];
