import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../redux/slices/projectSlice';
import { Modal, message } from 'antd';
import { ClockIcon, WarningIcon } from '../../icons';
import { sectorService } from '../../services';
import { socket } from '../../services/socket.service';
import { compareValues, getUserLanguage } from '../../utils';
import { useCallback } from 'react';
import { activeSectorFlag } from '../../utils/activeSectorFlag';
import { knowledgebaseRoutes } from '../../utils/knowledgebaseRoutes';
import { TimerManagerSingleton } from '../../utils/timerManager';

message.config({
  maxCount: 1
});

const WorkHourModal = ({
  hoursWorkModal,
  setHoursWorkModal,
  modalStyles,
  t,
  sectorObject,
  colors,
  permission,
  saveHandler = () => {}
}) => {
  const timerManager = TimerManagerSingleton.getInstance();
  const [confirm, setConfirm] = useState(false);
  const projectState = useSelector((state) => state.projectState);
  const dispatch = useDispatch();

  const userLang = getUserLanguage();
  const lang = userLang.includes('es') ? 'es' : 'en';
  const moreInfoURL = `https://knowledgebase.outbuild.com/${lang}/knowledge/${knowledgebaseRoutes[lang].workHours}`;

  async function getSectors() {
    const resp = await sectorService.index();
    return resp ? resp.sectors : false;
  }

  const goUpdateHours = useCallback(async () => {
    const copySector = { ...sectorObject };
    copySector.hoursPerDay = hoursWorkModal.hoursPerDay;
    copySector.hoursPerWeek = hoursWorkModal.hoursPerWeek;
    copySector.update_activities_work_hours = true;
    delete copySector.calendars;
    setConfirm(false);

    const res = await sectorService.update(copySector);

    if (res) {
      message.success(
        t('lang') === 'es'
          ? 'Horas de trabajo actualizadas para el sector, recargando...'
          : 'Stage working hours updated',
        2
      );
      const sectors = await getSectors();

      if (sectors) {
        const filterSectors = sectors.filter(
          (e) =>
            e.projectId === projectState.projectSelected && e.status === true
        );
        filterSectors.sort(compareValues('order'));

        await sectorService.updatePonderatorMaster(copySector.id, true);
        await sectorService.updatePonderatorLookahead(copySector.id, true);

        dispatch(projectActions.setAllSectors(filterSectors));

        socket.emit('hours_updated', {
          sector: projectState.sectorSelected
        });

        await saveHandler();

        timerManager.registerAutoTimeout(
          () => {
            window.location.reload();
          },
          1000,
          'reloadTimer'
        );
      }
    }
  }, [
    sectorObject,
    hoursWorkModal.hoursPerDay,
    hoursWorkModal.hoursPerWeek,
    projectState.projectSelected,
    projectState.sectorSelected,
    saveHandler,
    dispatch,
    t
  ]);

  const isValid = useCallback(() => {
    return (
      (sectorObject.hoursPerDay !== hoursWorkModal.hoursPerDay ||
        sectorObject.hoursPerWeek !== hoursWorkModal.hoursPerWeek) &&
      Number.isInteger(hoursWorkModal.hoursPerDay) &&
      Number.isInteger(hoursWorkModal.hoursPerWeek) &&
      hoursWorkModal.hoursPerDay != 0 &&
      hoursWorkModal.hoursPerWeek != 0
    );
  }, [hoursWorkModal, sectorObject]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setHoursWorkModal({
      ...hoursWorkModal,
      [name]: parseInt(value) ? parseInt(value) : ''
    });
  };

  const onValidateValue = useCallback(() => {
    const { hoursPerDay, hoursPerWeek } = hoursWorkModal;

    const MIN_HOURS_PER_DAY = 1;
    const MIN_HOURS_PER_WEEK = 1;
    const MAX_HOURS_PER_DAY = 24;
    const MAX_HOURS_PER_WEEK = 168;

    switch (true) {
      case Number.isNaN(hoursPerDay) ||
        hoursPerDay < MIN_HOURS_PER_DAY ||
        hoursPerDay > MAX_HOURS_PER_DAY:
        message.warning(
          t('modals.gantt.hours_worktime.hours_per_day_error'),
          2
        );
        break;
      case Number.isNaN(hoursPerWeek) ||
        hoursPerWeek < MIN_HOURS_PER_WEEK ||
        hoursPerWeek > MAX_HOURS_PER_WEEK:
        message.warning(
          t('modals.gantt.hours_worktime.hours_per_week_error'),
          2
        );
        break;
      default:
        if (isValid()) {
          setHoursWorkModal({ ...hoursWorkModal, visible: false });
          setConfirm(true);
        } else {
          setHoursWorkModal({ ...hoursWorkModal, visible: false });
        }
    }
  }, [hoursWorkModal, isValid, t]);

  const onCancel = () => {
    setHoursWorkModal({
      ...hoursWorkModal,
      visible: false,
      hoursPerDay: sectorObject.hoursPerDay,
      hoursPerWeek: sectorObject.hoursPerWeek
    });
  };

  return (
    <>
      {/* Work Hours modal */}
      <Modal
        width={300}
        wrapClassName={`activity-modification-style ${modalStyles['facelifted-modal']} ${modalStyles['work-hours-modal']}`}
        title={
          <>
            <ClockIcon color={colors.brandOrange40} /> {hoursWorkModal.title}
          </>
        }
        visible={hoursWorkModal.visible}
        onCancel={() => onCancel()}
        footer={[
          <button
            key="cancel"
            onClick={() => {
              onCancel();
            }}>
            {t('cancel')}
          </button>,
          <button
            key="new"
            data-type="main"
            onClick={onValidateValue}
            disabled={permission === 'V'}>
            {t('modals.gantt.hours_worktime.save_button')}
          </button>
        ]}>
        {
          <div className={modalStyles['work-hours-modal__content']}>
            <label>
              {t('modals.gantt.hours_worktime.hours_per_day_label')}
              <input
                type="number"
                value={hoursWorkModal.hoursPerDay}
                name="hoursPerDay"
                onChange={(e) => {
                  onChange(e);
                }}
              />
            </label>
            <label>
              {t('modals.gantt.hours_worktime.hours_per_week_label')}
              <input
                type="number"
                name="hoursPerWeek"
                value={hoursWorkModal.hoursPerWeek}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            </label>
            <div style={{ textAlign: 'right' }}>
              <a
                href={moreInfoURL}
                target="_blank"
                className={modalStyles['work-hours-modal__link']}>
                {t('more_info')}
              </a>
            </div>
          </div>
        }
      </Modal>
      {/* Confirmation modal */}
      {confirm && (
        <Modal
          width={490}
          height={275}
          wrapClassName={`activity-modification-style ${modalStyles['gantt-alert-modal']} ${modalStyles['confirm-modal']}`}
          visible={confirm}
          onCancel={() => setConfirm(false)}
          footer={[
            <button
              key="cancel"
              onClick={() => {
                setConfirm(false);
                setHoursWorkModal({ ...hoursWorkModal, visible: true });
              }}>
              {t('modals.gantt.confirm_modal_save_reload.cancel_button')}
            </button>,
            <button
              key="new"
              data-type="main"
              onClick={() => {
                goUpdateHours();
              }}>
              {t('modals.gantt.confirm_modal_save_reload.continue_button')}
            </button>
          ]}>
          {
            <>
              <div className="icon-center">
                <WarningIcon size={48} color={colors.brandOrange40} />
              </div>

              <div className="body-center">
                <p>
                  {t('modals.gantt.confirm_modal_save_reload.content_modal')}
                </p>
              </div>

              <div className="body-center">
                {t('modals.gantt.confirm_modal_save_reload.save_continue')}
              </div>
            </>
          }
        </Modal>
      )}
    </>
  );
};

export default WorkHourModal;
